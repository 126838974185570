import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/whattowatch.css';

loadCSS('whattowatch-site');

export const WhattowatchSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default WhattowatchSiteWrapper;
